import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import { useEffect } from 'react'
import { useLocation, withRouter } from 'react-router-dom'

import { useTranslation } from 'react-i18next'

import CookieConsent from 'react-cookie-consent'

import { PATH_ROOT, PATH_404 } from './constants'
import { HomePage, GeneralLayout, NotFoundPage } from './components'

// const NewsAndMediaPage = lazy(() => import('./components/NewsAndMedia/lazyNewsAndMedia'))
// const News = lazy(() => import('./components/NewsAndMedia/lazyNews'))

// eslint-disable-next-line
function _ScrollToTop(props: any) {
  const { pathname } = useLocation()
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])
  return props.children
}
const ScrollToTop = withRouter(_ScrollToTop)

export const AppRoutes: React.FC = () => {
  const { pathname } = useLocation()
  const { t } = useTranslation()
  return (
    <>
      <Switch>
        <Route path={PATH_404} component={NotFoundPage} />
      </Switch>
      {/* <CrossBorderServicesCheckerModal /> */}
      {pathname !== PATH_404 && (
        <>
          <GeneralLayout>
            <ScrollToTop>
              <Switch>
                <Route path={PATH_ROOT} exact component={HomePage} />
                {/* <Route path={PATH_ABOUT_PINOBO} exact component={UseCasesPage} />
                <Route path={PATH_OVERVIEW} exact component={UseCasesPage} />
                <Route path={PATH_MARKET_PLACES} exact component={OnlineMarketPlacePage} />
                <Route path={PATH_MEDIA_INFLUENCERS} exact component={MediaInfluecersPage} />
                <Route path={PATH_AFFILIATE_NETWORKS} exact component={AffiliateNetworkPage} />
                <Route path={PATH_BOARD_DIRECTORS} exact component={BoardDirectorsPage} />
                <Route path={PATH_EXECUTIVE_MANAGEMENT} exact component={ExecutiveManagementPage} />
                <Route path={PATH_ADVISORS} exact component={AdvisorsPage} />
                <Route path={PATH_NEWS_AND_MEDIA} exact component={NewsAndMediaPage} />
                <Route path={PATH_ECOMMERCE} exact component={EcommercePage} />
                <Route path={PATH_FINTECHS} exact component={FintechsPage} />
                <Route
                  path={PATH_VIRTUAL_IBAN_ACCOUNTS}
                  exact
                  component={VirtualIbanAccountsPage}
                />
                <Route
                  path={PATH_CROSS_BORDER_PAYMENTS}
                  exact
                  component={CrossBorderPaymentsPage}
                />
                <Route path={PATH_API} exact component={ApiPage} />
                <Route path={PATH_APPLE_PAY} exact component={ApplePayPage} />
                <Route path={PATH_GOOGLE_PAY} exact component={GooglePayPage} />
                <Route path={PATH_SERVICES} exact component={CardsPage} />
                <Route path={PATH_TECHNOLOGIES} exact component={AboutPage} />
                <Route path={PATH_KLARPAY} exact component={AboutPage} />
                <Route path={PATH_TALKTOUS} exact component={TalkToUs} />
                <Route path={PATH_SOLUTIONS} exact component={PartnershipsPage} />
                <Route path={PATH_IMPRESSUM} exact component={ImpressumPage} />
                <Route path={PATH_IMPRINT} exact component={ImpressumPage} />
                <Route path={PATH_CORRESPONDENT_BANKS} exact component={CorrespondentBanksPage} />
                <Route path={PATH_DATA_PROCESSORS} exact component={DataProcessorsPage} />
                <Route path={PATH_PRIVACY_POLICY} exact component={PrivacyPolicyPage} />
                <Route path={PATH_NEWS_PAGES} exact component={NewsAndMediaPage} />
                <Route path={PATH_NEWS_PAGES_CATEGORY} exact component={NewsAndMediaPage} />
                <Route path={PATH_NEWS_PAGES_CATEGORY_PAGES} exact component={NewsAndMediaPage} />
                <Route path={PATH_NEWS_ENTRIES} component={News} />
                <Route path={PATH_FAQ} component={FaqPage} />
                <Route path={PATH_MESSAGE_SENT} exact component={MessageSent} /> */}
                <Redirect to={PATH_404} />
              </Switch>
              {/* <CookieConsent
                declineButtonText={t('COMMON-Cookie-Button-Decline')}
                style={{
                  fontFamily: '"basier_square", "Helvetica", "Arial", sans-serif',
                  backgroundColor: 'rgba(0, 0, 0, 0.9)',
                  textAlign: 'center',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignContent: 'center',
                }}
                contentStyle={{
                  flex: 'inherit',
                }}
                buttonStyle={{
                  color: '#fff',
                  fontFamily: '"basier_square", "Helvetica", "Arial", sans-serif',
                  fontWeight: 'bold',
                  backgroundColor: '#EF2828',
                }}
              >
                {t('COMMON-Cookie-Text')}
              </CookieConsent> */}
            </ScrollToTop>
          </GeneralLayout>
        </>
      )}
    </>
  )
}
