import React, { FC, useCallback } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'
import { Link } from '@material-ui/core'
import { PATH_CORRESPONDENT_BANKS, PATH_IMPRESSUM } from '../../constants'
import { useHistory } from 'react-router'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  footer: {
    padding: '24px 64px',
    background: theme.palette.primary.dark,
    color: theme.palette.secondary.light,
    [theme.breakpoints.down('sm')]: {
      padding: '16px 24px',
    },
  },
  copyright: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      order: 2,
      textAlign: 'center',
      '& span': {
        display: 'inline-block',
        margin: theme.spacing(2, 0),
      },
    },
  },
  footerNav: {
    display: 'flex',
    justifyContent: 'flex-end',
    // alignItems: 'baseline',
    [theme.breakpoints.down('sm')]: {
      order: 1,
      flexDirection: 'column',
      alignItems: 'normal',
      textAlign: 'center',
    },
    '& .MuiLink-button': {
      fontSize: '0.75rem',
      lineHeight: '24px',
      fontWeight: 'normal',
      opacity: 0.8,
      '&:hover': {
        opacity: 1,
      },
      [theme.breakpoints.down('sm')]: {
        margin: theme.spacing(2, 0),
      },
      [theme.breakpoints.up('md')]: {
        marginLeft: theme.spacing(8),
        '&:last-child': {
          marginLeft: theme.spacing(8),
        },
      },
    },
  },
  dividerLine: {
    backgroundColor: theme.palette.secondary.light,
    opacity: '24%',
  },
  txtStyle: {
    opacity: '80%',
    fontSize: '0.75rem',
    marginBottom: theme.spacing(1),
  },
  footerLink: {
    textAlign: 'left',
  },
}))

export const Footer: FC = () => {
  const classes = useStyles()
  const history = useHistory()
  const { t } = useTranslation()

  const buttonHandler = useCallback(
    (route) => {
      history.push(route)
    },
    [history],
  )

  return (
    <>
      {/* <Box className={classes.footer}>
        <Grid container>
          <Grid item xs={12} md={6} className={classes.copyright}>
            <Box component="span" className={classes.txtStyle}>
              <Typography className={classes.txtStyle}>{t('Footer-P1')}</Typography>
              <Typography className={classes.txtStyle}>{t('Footer-P2')}</Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Box className={classes.footer} justifyContent={'center'}>
        <Grid container>
          <Grid item xs={12} className={classes.copyright}>
            <Box component="span" className={classes.txtStyle}>
              &copy; {t('Footer-Copyright')}
            </Box>
          </Grid>
        </Grid>
      </Box> */}
    </>
  )
}
