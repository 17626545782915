import React, { FC } from 'react'
import { Container, makeStyles } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import logo from '../../assets/images/pinobo-logo-white.svg'
import Typography from '@material-ui/core/Typography'
import clsx from 'clsx'
import { ReactComponent as LinkedIn } from '../../assets/icons/linkedin.svg'
import { ReactComponent as Twitter } from '../../assets/icons/twitter.svg'
import { PATH_ROOT } from '../../constants'
import { Theme } from '@material-ui/core/styles'
import { Link } from 'react-router-dom'
import IconButton from '@material-ui/core/IconButton'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.secondary.light,
    [theme.breakpoints.up('md')]: {
      '& .MuiListItem-gutters': {
        marginLeft: '-16px',
      },
    },
    '& .MuiContainer-root': {
      // maxWidth: 1072,
    },
  },
  subFooterGrid: {
    // justifyContent: 'space-between',
    flexWrap: 'nowrap',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      // alignItems: 'center',
      // whiteSpace: 'nowrap',
      // textAlign: 'center',
    },
    [theme.breakpoints.down('xs')]: {
      alignItems: 'center',
      textAlign: 'center',
    },
  },
  footerNavColumnList: {
    display: 'block',
    marginTop: '22px',
    [theme.breakpoints.down('sm')]: {
      '& .MuiListItem-root': {
        textAlign: 'center',
      },
    },
  },
  body: {
    fontFamily: '"Helvetica", "Arial", sans-serif',
    fontSize: '1rem',
    textDecoration: 'unset',
    color: 'black',
  },
  body2: {
    display: 'block',
    marginBottom: '22px',
    fontFamily: '"Helvetica", "Arial", sans-serif',
    fontSize: '0.875rem',
    opacity: '64%',
    textDecoration: 'none',
    color: 'black',
    whiteSpace: 'normal',
    '&:hover': {
      opacity: 1,
      textDecoration: 'underline',
    },
  },
  mrgTop: {
    marginTop: theme.spacing(5),
  },
  subFooterNav: {
    justifyContent: 'space-around',
    '& .MuiListItemText-primary': {
      fontSize: 'inherit',
    },
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  subFooterNavItem: {
    padding: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      width: '20%',
      maxWidth: 200,
      minWidth: 100,
    },
    '& .MuiTypography-subtitle2': {
      fontSize: 'inherit',
    },
  },
  socials: {
    color: '#555',
    '& .MuiIconButton-root': {
      marginTop: theme.spacing(-1.5),
    },
    [theme.breakpoints.only('sm')]: {
      marginLeft: theme.spacing(-1),
    },
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
    marginBottom: theme.spacing(5),
  },
  logoImg: {
    display: 'inline-block',
    width: '120px',
  },
  phrase: {
    fontSize: '14px',
  },
  leftMargin: {
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(-2),
    },
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
    },
  },
  copyright: {
    display: 'flex',
    justifyContent: 'left',
    marginTop: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      order: 2,
      textAlign: 'center',
      '& span': {
        display: 'inline-block',
        margin: theme.spacing(2, 0),
      },
    },
  },
  txtStyle: {
    opacity: '80%',
    fontSize: '0.75rem',
    marginBottom: theme.spacing(1),
  },
}))

export const SubFooter: FC = () => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <Box pt={6} mt={6} className={classes.root}>
      <Container>
        <Grid container spacing={3} className={classes.subFooterGrid}>
          <Grid item xs={12} md={3}>
            <Grid container direction={'column'}>
              <Grid item>
                <Box className={classes.socials}>
                  <Link className={classes.logoImg} to={PATH_ROOT}>
                    <img src={logo} width="100%" alt={'Pinobo OU'} />
                  </Link>
                </Box>
              </Grid>
              {/* <Grid item>
                <Grid item>
                  <Grid container spacing={1} className={clsx(classes.socials, classes.leftMargin)}>
                    <IconButton
                      aria-label="LinkedIn"
                      target="_blank"
                      href="https://www.linkedin.com/company/pinobo-com"
                    >
                      <LinkedIn />
                    </IconButton>
                    <IconButton
                      aria-label="Twitter"
                      target="_blank"
                      href="https://twitter.com/pinobocom"
                    >
                      <Twitter />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid> */}
              <Grid item xs={12} className={classes.copyright}>
                <Box component="span" className={classes.txtStyle}>
                  &copy; {t('Footer-Copyright')}
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={3}>
            <Typography className={classes.phrase}>
              <div dangerouslySetInnerHTML={{ __html: t('Footer-P1') }} />
            </Typography>
          </Grid>
          <Grid item xs={12} md={2}>
            <Box>
              <Typography className={classes.phrase}>
                <div dangerouslySetInnerHTML={{ __html: t('Footer-P2') }} />
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}
