import React, { FC } from 'react'
import { makeStyles, Theme, createStyles } from '@material-ui/core'
import { Header } from '../Header'
import { Footer } from '../Footer'
import { SubFooter } from '../SubFooter'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      flexGrow: 1,
      padding: theme.spacing(0),
      marginTop: theme.spacing(9),
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(0),
        paddingTop: theme.spacing(5),
        marginTop: theme.spacing(0),
      },
      '& .MuiContainer-root': {
        maxWidth: '100%',
        padding: theme.spacing(0),
      },
    },
  }),
)

export const GeneralLayout: FC = ({ children }) => {
  const classes = useStyles()

  return (
    <>
      <Header />
      <main className={classes.content}>{children}</main>
      <SubFooter />
      <Footer />
    </>
  )
}
