import { FC, default as React, useCallback } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import CardMedia from '@material-ui/core/CardMedia'
import { ReactComponent as MultiCurrencyAccount } from '../../assets/icons/icon-multi-currency.svg'
import { ReactComponent as AcceptCard } from '../../assets/icons/icon-cards.svg'
import { ReactComponent as CompanyName } from '../../assets/icons/icon-unique-iban.svg'
import { ReactComponent as UberTransparent } from '../../assets/icons/icon-fees.svg'
import { ReactComponent as MarketPlaces } from '../../assets/icons/icon-marketplace.svg'
import { ReactComponent as GlobalEcommerce } from '../../assets/icons/icon-ecommerce.svg'
import { ReactComponent as AffilateNetwork } from '../../assets/icons/icon-networks.svg'
import { ReactComponent as MarketingInfl } from '../../assets/icons/icon-user.svg'
import { ReactComponent as FinTechSps } from '../../assets/icons/icon-fintech.svg'
import { ReactComponent as IconArrow } from '../../assets/icons/icon-arrow.svg'
import imgHero2x from '../../assets/images/hero-1@2x.png'
import circleImg from '../../assets/images/pinoboCircle@2x.png'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import { Hidden, MenuItem, MenuList } from '@material-ui/core'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'
import { PATH_TALKTOUS } from '../../constants'
import {
  PATH_AFFILIATE_NETWORKS,
  PATH_ECOMMERCE,
  PATH_FINTECHS,
  PATH_MARKET_PLACES,
  PATH_MEDIA_INFLUENCERS,
} from '../../constants'
import { useHistory } from 'react-router'
import { tagManagerHandler } from '../../utils'

const useStyles = makeStyles((theme) => ({
  heroContent: {
    backgroundColor: theme.palette.background.paper,
  },
  heroContentPref: {
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(6),
  },
  circleImg: {
    display: 'inline-block',
    width: '90%',
    margin: 'auto',
    [theme.breakpoints.down('sm')]: {
      width: '50%',
    },
  },
  readMoreButton: {
    width: 216,
    background: 'black',
    color: 'white',
    fontWeight: 700,
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: '#ef2828',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  footerNavColumns: {
    justifyContent: 'space-between',
  },
  mrgbtm: {
    marginBottom: theme.spacing(12),
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(4),
    },
  },
  footerNavColumnList: {
    display: 'block',

    MuiListItemButton: {
      padding: 0,
    },
  },
  heroContentPrefHeader: {
    marginBottom: theme.spacing(5),
    [theme.breakpoints.down('sm')]: {
      paddingTop: 0,
    },
  },
  subtitleBoxIcon: {
    fontWeight: 900,
    body1: {
      fontSize: '1.125rem',
      fontWeight: 'normal',
    },
  },

  menuItem: {
    flexWrap: 'wrap',
    padding: theme.spacing(1.5),
    [theme.breakpoints.down('sm')]: {
      marginTop: 50,
    },

    '& .MuiMenuItem-root': {
      width: '50%',
      padding: theme.spacing(2.5),
      justifyContent: 'center',
      whiteSpace: 'normal',
    },

    '& .MuiListItem-button:hover': {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.secondary.contrastText,
    },

    '& .img:hover': { backgroundColor: theme.palette.secondary.main },

    '& .menuItemInner': {
      display: 'flex',
      alignItems: 'top',
      paddingTop: theme.spacing(0),
      borderTop: '8px solid transparent',
      transition: 'border-top-color 0.2s, font-weight 0.2s',
      position: 'relative',
      top: 0,
    },
    '&:hover': {
      backgroundColor: 'transparent',

      '& .MuiListItemIcon-root': {
        opacity: 1,
      },
    },
    '&:hover .menuItemInner': {
      backgroundColor: 'green',
    },
    '&.Mui-selected': {
      backgroundColor: 'transparent',
      background: theme.palette.secondary.main,
      '& .menuItemInner': {
        background: theme.palette.secondary.main,
      },
      '& .MuiListItemIcon-root': {
        opacity: 1,
        // color: theme.palette.secondary.main,
      },
      '& .MuiTypography-body1': {},
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
  },
  minW: {
    minWidth: '250px',
  },
  maxW: {
    maxWidth: '1280px',
    margin: '0 auto',
    padding: '0 20px',
  },
  imgWrap: {
    textAlign: 'center',
  },
  row: {
    marginTop: theme.spacing(8),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(4),
      textAlign: 'center',
    },
    '& .MuiTypography-h3': {
      marginBottom: theme.spacing(3),
      [theme.breakpoints.down('sm')]: {
        textAlign: 'center',
      },
    },
  },
  pageTitle: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(5),
    [theme.breakpoints.down('sm')]: {
      fontSize: '2.75rem',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '2.25rem',
    },
  },
  rowTitle: {
    marginBottom: theme.spacing(5),
    [theme.breakpoints.down('sm')]: {
      fontSize: '2rem',
      textAlign: 'center',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.5rem',
    },
  },
  rowText: {
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
  },
  extraMargin: {
    marginTop: theme.spacing(20),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(8),
    },
  },
  features: {
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
  buttonContainer: {
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
      width: '100%',
    },
  },
  getStartedButton: {
    fontWeight: 700,
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  backgroundImageWrapper: {
    backgroundColor: theme.palette.secondary.main,
  },
  backgroundImageContainer: {
    background: `url(${imgHero2x}) no-repeat center center`,
    padding: theme.spacing(24, 24),
    color: 'white',
    textShadow: '3px 3px 10px rgba(0, 0, 0, 0.25)',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(4, 4),
    },
  },
}))

export const HomePage: FC = () => {
  const classes = useStyles()
  const history = useHistory()
  const { t } = useTranslation()

  const buttonHandler = useCallback(
    (route) => {
      history.push(route)
    },
    [history],
  )

  const routerHandler = useCallback((path) => {
    history.push(path)
  }, [])

  tagManagerHandler('PageHome')

  return (
    <>
      <Container className={classes.backgroundImageWrapper}>
        <Box className={classes.backgroundImageContainer}>
          <Box className={classes.maxW}>
            <Grid item xs={12} container>
              <Typography
                component="h1"
                variant={'h1'}
                align="left"
                gutterBottom
                className={classes.pageTitle}
              >
                {t('Home-H1')}
              </Typography>
              <Typography variant="subtitle1" align="left" component={'div'} gutterBottom>
                {t('Home-P11')}
                <br />
                {t('Home-P12')}
              </Typography>
            </Grid>
          </Box>
        </Box>
      </Container>

      <Box className={classes.maxW}>
        <Container>
          <Box className={classes.row}>
            <Grid container spacing={4} className={classes.features}>
              <Grid item xs={12} md={4}>
                <Typography component="h3" variant={'h3'}>
                  {t('Home-H2')}
                </Typography>
                <Typography>{t('Home-P21')}</Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography component="h3" variant={'h3'}>
                  {t('Home-H3')}
                </Typography>
                <Typography>{t('Home-P31')}</Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography component="h3" variant={'h3'}>
                  {t('Home-H4')}
                </Typography>
                <Typography>{t('Home-P41')}</Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography component="h3" variant={'h3'}>
                  {t('Home-H5')}
                </Typography>
                <Typography>{t('Home-P51')}</Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography component="h3" variant={'h3'}>
                  {t('Home-H6')}
                </Typography>
                <Typography>{t('Home-P61')}</Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography component="h3" variant={'h3'}>
                  {t('Home-H7')}
                </Typography>
                <Typography>{t('Home-P71')}</Typography>
              </Grid>
            </Grid>
          </Box>

          <Box className={clsx(classes.row /*, classes.extraMargin*/)}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6} className={classes.rowText}>
                <Typography variant={'h2'} className={classes.rowTitle}>
                  {t('Home-H8')}
                  <br /> {t('Home-H81')}
                </Typography>
                <Typography className={classes.heroContentPrefHeader}>{t('Home-P81')}</Typography>
              </Grid>

              <Grid item xs={12} md={6}>
                <Grid container spacing={3}>
                  <CardMedia
                    className={classes.circleImg}
                    component="img"
                    alt="Pinobo Holistic"
                    image={circleImg}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Box>

          <Box className={classes.row}>
            <Grid container spacing={8}>
              <Grid item xs={12} md={6}>
                <Grid container spacing={5}>
                  <MenuList className={classes.menuItem}>
                    <MenuItem>
                      <Box component={'div'} width={'200px'}>
                        <Box padding={'22px'}>
                          <Typography className={classes.subtitleBoxIcon} align={'center'}>
                            {t('Home-H12')}
                          </Typography>
                        </Box>
                      </Box>
                    </MenuItem>

                    <MenuItem>
                      <Box component={'div'} width={'200px'}>
                        <Box padding={'22px'}>
                          <Typography className={classes.subtitleBoxIcon} align={'center'}>
                            {t('Home-H13')}
                          </Typography>
                        </Box>
                      </Box>
                    </MenuItem>

                    <MenuItem>
                      <Box component={'div'} width={'200px'}>
                        <Box padding={'22px'}>
                          <Typography className={classes.subtitleBoxIcon} align={'center'}>
                            {t('Home-H14')}
                          </Typography>
                        </Box>
                      </Box>
                    </MenuItem>

                    <MenuItem>
                      <Box component={'div'} width={'200px'}>
                        <Box padding={'22px'}>
                          <Typography className={classes.subtitleBoxIcon} align={'center'}>
                            {t('Home-H15')}
                          </Typography>
                        </Box>
                      </Box>
                    </MenuItem>

                    <MenuItem>
                      <Box component={'div'} width={'200px'}>
                        <Box padding={'22px'}>
                          <Typography className={classes.subtitleBoxIcon} align={'center'}>
                            {t('Home-H16')}
                          </Typography>
                        </Box>
                      </Box>
                    </MenuItem>

                    <MenuItem>
                      <Box component={'div'} width={'200px'}>
                        <Box padding={'22px'}>
                          <Typography className={classes.subtitleBoxIcon} align={'center'}>
                            {t('Home-H17')}
                          </Typography>
                        </Box>
                      </Box>
                    </MenuItem>
                  </MenuList>
                </Grid>
              </Grid>
              <Grid item xs={12} md={6} className={classes.rowText}>
                <Typography variant={'h2'} className={classes.rowTitle}>
                  {t('Home-H11')}
                </Typography>
                <br />
                <Typography>{t('Home-P111')}</Typography>
              </Grid>
            </Grid>
          </Box>

          <Box className={classes.row}>
            <Grid container spacing={8}>
              <Grid item xs={12} md={6} className={classes.rowText}>
                <Typography variant={'h2'} className={classes.rowTitle}>
                  {t('Home-H18')}
                </Typography>
                <br />
                <Typography>{t('Home-P181')}</Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Grid container spacing={5}>
                  <MenuList className={classes.menuItem}>
                    <MenuItem>
                      <Box component={'div'} width={'200px'}>
                        <Box padding={'22px'}>
                          <Typography className={classes.subtitleBoxIcon} align={'center'}>
                            {t('Home-H19')}
                          </Typography>
                        </Box>
                      </Box>
                    </MenuItem>

                    <MenuItem>
                      <Box component={'div'} width={'200px'}>
                        <Box padding={'22px'}>
                          <Typography className={classes.subtitleBoxIcon} align={'center'}>
                            {t('Home-H20')}
                          </Typography>
                        </Box>
                      </Box>
                    </MenuItem>

                    <MenuItem>
                      <Box component={'div'} width={'200px'}>
                        <Box padding={'22px'}>
                          <Typography className={classes.subtitleBoxIcon} align={'center'}>
                            {t('Home-H21')}
                          </Typography>
                        </Box>
                      </Box>
                    </MenuItem>

                    <MenuItem>
                      <Box component={'div'} width={'200px'}>
                        <Box padding={'22px'}>
                          <Typography className={classes.subtitleBoxIcon} align={'center'}>
                            {t('Home-H22')}
                          </Typography>
                        </Box>
                      </Box>
                    </MenuItem>

                    <MenuItem>
                      <Box component={'div'} width={'200px'}>
                        <Box padding={'22px'}>
                          <Typography className={classes.subtitleBoxIcon} align={'center'}>
                            {t('Home-H23')}
                          </Typography>
                        </Box>
                      </Box>
                    </MenuItem>

                    <MenuItem>
                      <Box component={'div'} width={'200px'}>
                        <Box padding={'22px'}>
                          <Typography className={classes.subtitleBoxIcon} align={'center'}>
                            {t('Home-H24')}
                          </Typography>
                        </Box>
                      </Box>
                    </MenuItem>
                  </MenuList>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Container>

        <Box className={clsx(classes.row, classes.extraMargin)}>
          <Box mt={8}></Box>
        </Box>
      </Box>
    </>
  )
}
