import { createFilterOptions } from '@material-ui/lab/Autocomplete'

import { ICountryEnum } from '@klarpay/klarpay-common-enums-js/dist'
import { countries } from '@klarpay/klarpay-common-enums-js'
import { emailEnquiryTypes } from '@klarpay/klarpay-common-enums-js/dist/enums/emailEnquiry'

export const emailEnquiryArray = Object.values<any>(emailEnquiryTypes).sort((a) => {
  if (a?.key === 'klarpayCorporateAccount') {
    return -3
  } else if (a?.key === 'introducingPartner') {
    return -2
  } else if (a?.key === 'generalEnquiries') {
    return -1
  } else {
    return 0
  }
})

export const countriesArray = Object.values<ICountryEnum>(countries).sort(
  (a, b) => -b.label.localeCompare(a.label),
)

export const prefixCode = (phoneCountryCode = DEFAULT_COUNTRY_CALLING_CODE) => {
  return `+${countries[phoneCountryCode?.toLowerCase() as keyof typeof countries]?.callingCode}`
}
export const filterOptions = createFilterOptions({
  matchFrom: 'start',
})

export const DEFAULT_COUNTRY_CALLING_CODE = 'CH'
export const MIN_LENGTH = 2
export const MAX_BUSINESS_LENGTH = 500
export const MAX_ENTRIES_PER_PAGE = 5
export const MIN_WIDTH_FOR_NEWS = 960
export const LATINICA_ONLY_REGEXP = /^[ "'`&.,\-a-zA-ZA-Za-zÀ-ž](?:[ "'`&$€£.,\-a-zA-ZA-Za-zÀ-ž]){1,}$/
export const COMPANY_NAME_REGEX = /^[ "'`&.,\-a-zA-Z0-9A-Za-zÀ-ž](?:[ "'`&$€£.,\(\)\-a-zA-Z0-9A-Za-zÀ-ž]){1,}$/
export const ALL_EXCEPT_CYRILLIC_REGEXP = /^[^а-яё]+$/iu

export const BASEURL = process.env.REACT_APP_API_BASE_URL || 'https://graphql.klarpay.com/api'
