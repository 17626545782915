import React, { FC, useCallback, useState } from 'react'
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles'
import { Hidden, IconButton, List, MenuList, Toolbar, useTheme } from '@material-ui/core'
import { MainMenuItem } from './MainMenuItem'
import { MainSubMenuItem } from './MainSubMenuItem'
import {
  PATH_MARKET_PLACES,
  PATH_MEDIA_INFLUENCERS,
  PATH_ECOMMERCE,
  PATH_FINTECHS,
  PATH_TECHNOLOGIES,
  PATH_ABOUT_PINOBO,
  PATH_BOARD_DIRECTORS,
  PATH_EXECUTIVE_MANAGEMENT,
  PATH_NEWS_AND_MEDIA,
  PATH_AFFILIATE_NETWORKS,
  PATH_OVERVIEW,
  PATH_KLARPAY,
  PATH_APPLE_PAY,
  PATH_GOOGLE_PAY,
  PATH_SERVICES,
  PATH_ADVISORS,
  PATH_NEWS_ENTRIES,
  PATH_CROSS_BORDER_PAYMENTS,
  PATH_VIRTUAL_IBAN_ACCOUNTS,
  PATH_API,
  PATH_SOLUTIONS,
  PATH_FAQ,
  PATH_TALKTOUS,
  PATH_PRICING,
  PATH_PORTFOLIO,
} from '../../constants'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Drawer from '@material-ui/core/Drawer'
import Typography from '@material-ui/core/Typography'
import MenuIcon from '@material-ui/icons/Menu'
import MenuIconClose from '@material-ui/icons/Close'
import clsx from 'clsx'
import { ReactComponent as Logo } from '../../assets/images/pinobo-logo.svg'
import { ReactComponent as ArrowDown } from '../../assets/icons/arrow-down.svg'
// import { LanguageSwitch } from '../LanguageSwitch'
import { Login } from '../Login'
import { Account } from '../Account'
import { useTranslation } from 'react-i18next'

const drawerWidth = '100%'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      '& .MuiPaper-elevation4': {
        boxShadow: 'none',
      },
      Box: {
        padding: theme.spacing(2),
      },
    },
    '& div#root, body, html': {
      background: 'white',
    },
    tp: {
      padding: 0,
    },
    menuButton: {
      position: 'absolute',
      right: 16,
      top: 10,
    },
    title: {
      flexGrow: 1,
    },
    icon: {
      color: 'black',
    },
    accountIcon: {
      fontSize: '40px',
    },
    toolBar: {
      minHeight: '6rem',
      paddingLeft: theme.spacing(5),
    },
    mainMenu: {},

    fullList: {
      display: 'flex',
      width: 'auto',
      flexFlow: 'wrap',
    },
    aboutList: {
      width: 250,
    },
    drawerSize: {
      height: '280px',
    },
    description: {
      // opacity: '60%',
      fontWeight: 'normal',
      paddingTop: 12,
    },
    grid: {
      alignItem: 'center',
      margin: '55px 0 55px 0',
    },
    grid2: {
      padding: '10px 0 10px 0',
    },
    subMenuWrap: {
      maxWidth: '1280px',
      margin: '0 auto',
      padding: theme.spacing(2.5),
      paddingTop: '75px',
    },
    drawer: {
      [theme.breakpoints.up('md')]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    drawerPaper: {
      width: drawerWidth,
      border: 'none',
    },
    logoMenu: {
      display: 'flex',
      justifyContent: 'space-between',
      minHeight: 64,
      padding: '18px 30px 15px',
    },
    nested: {
      paddingLeft: theme.spacing(4),
    },
    LeftBlockMenu: { paddingTop: '20px !Important' },

    titleLeftBlockMenu: {
      fontWeight: 'bold',
    },
    mobMenuItemBold: {
      color: 'red',
    },
    emptyItemMenuItem: {
      opacity: 1,
      fontSize: '1.25rem',
      fontWeight: 'bold',
      textAlign: 'center',
    },
    emptyItemMenuItemBox: { textAlign: 'center', paddingTop: '8px', paddingBottom: '8px' },
    maxW: {
      maxWidth: '1280px',
      margin: '0 auto',
    },
  }),
)

const GridRowFirst: FC = ({ children }) => {
  const classes = useStyles()

  return (
    <Grid item xs={10}>
      <Grid container spacing={1} className={classes.grid2}>
        {children}
      </Grid>
    </Grid>
  )
}

const GridRowItem: FC = ({ children }) => {
  const classes = useStyles()
  return (
    <Grid container spacing={1} className={classes.grid2} alignItems={'center'}>
      {children}
    </Grid>
  )
}

export const MainMenu: FC = () => {
  const classes = useStyles()
  const theme = useTheme()
  const { t } = useTranslation()
  type Anchor = 'top'
  const [state, setState] = useState<boolean>(false)
  const [feature, setFeature] = useState<boolean>(false)
  const [mobileOpen, setMobileOpen] = useState<boolean>(false)
  const [stateAbout, setStateAbout] = useState<boolean>(false)

  const toggleDrawerMobile = (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return
    }
    setMobileOpen(!mobileOpen)
  }

  const list = useCallback(() => {
    return (
      <Box className={classes.subMenuWrap}>
        <Grid container justify={'center'} spacing={5}>
          <GridRowFirst>
            <Grid item xs={12}>
              <GridRowItem>
                <Grid item xs={4}>
                  <MainSubMenuItem primary={t('MainMenu-P3')} path={PATH_ABOUT_PINOBO} />
                </Grid>
                <Grid item xs={4}>
                  <MainSubMenuItem primary={t('MainMenu-P4')} path={PATH_AFFILIATE_NETWORKS} />
                </Grid>
                <Grid item xs={4}>
                  <MainSubMenuItem primary={t('MainMenu-P5')} path={PATH_MARKET_PLACES} />
                </Grid>
              </GridRowItem>
              <GridRowItem>
                <Grid item xs={4}>
                  <MainSubMenuItem primary={t('MainMenu-P6')} path={PATH_MEDIA_INFLUENCERS} />
                </Grid>
                <Grid item xs={4}>
                  <MainSubMenuItem primary={t('MainMenu-P7')} path={PATH_ECOMMERCE} />
                </Grid>
                <Grid item xs={4}>
                  <MainSubMenuItem primary={t('MainMenu-P8')} path={PATH_FINTECHS} />
                </Grid>
              </GridRowItem>
            </Grid>
            <Grid item xs={12} className={classes.LeftBlockMenu}>
              <Typography
                component={'span'}
                variant={'body2'}
                className={classes.titleLeftBlockMenu}
              >
                {t('MainMenu-P1')}
              </Typography>
              <Typography paragraph={true} className={classes.description}>
                {t('MainMenu-P2')}
              </Typography>
            </Grid>
          </GridRowFirst>
        </Grid>
      </Box>
    )
  }, [])

  const listFeatures = useCallback(() => {
    return (
      <Box className={classes.subMenuWrap}>
        <Grid container justify={'center'} spacing={5}>
          <GridRowFirst>
            <Grid item xs={3} className={classes.LeftBlockMenu}>
              <Typography
                component={'span'}
                variant={'body2'}
                className={classes.titleLeftBlockMenu}
              >
                {t('MainMenu-P17')}
              </Typography>
              <Typography paragraph={true} className={classes.description}>
                {t('MainMenu-P18')}
              </Typography>
            </Grid>

            <Grid item xs={1} />

            <Grid item xs={8}>
              <GridRowItem>
                <Grid item xs={4}>
                  <MainSubMenuItem primary={t('MainMenu-P26')} path={PATH_VIRTUAL_IBAN_ACCOUNTS} />
                </Grid>
                <Grid item xs={4}>
                  <MainSubMenuItem primary={t('MainMenu-P25')} path={PATH_CROSS_BORDER_PAYMENTS} />
                </Grid>
                <Grid item xs={4}>
                  <MainSubMenuItem primary={t('MainMenu-P27')} path={PATH_API} />
                </Grid>
                <Grid item xs={4}>
                  <MainSubMenuItem primary={t('MainMenu-P23')} path={PATH_SERVICES} />
                </Grid>
                <Grid item xs={4}>
                  <MainSubMenuItem primary={t('MainMenu-P19')} path={PATH_APPLE_PAY} />
                </Grid>
                <Grid item xs={4}>
                  <MainSubMenuItem primary={t('MainMenu-P24')} path={PATH_GOOGLE_PAY} />
                </Grid>
              </GridRowItem>
            </Grid>
          </GridRowFirst>
        </Grid>
      </Box>
    )
  }, [])

  const listAbout = useCallback((anchor: Anchor) => {
    return (
      <Box className={classes.subMenuWrap}>
        <Grid container justify={'center'} spacing={5}>
          <GridRowFirst>
            <Grid item xs={3} className={classes.LeftBlockMenu}>
              <Typography
                component={'span'}
                variant={'body2'}
                className={classes.titleLeftBlockMenu}
              >
                {t('MainMenu-P9')}
              </Typography>
              <Typography className={classes.description}>{t('MainMenu-P10')}</Typography>
            </Grid>

            <Grid item xs={1} />

            <Grid item xs={8}>
              <Box
                className={clsx({
                  [classes.fullList]: anchor === 'top',
                })}
                role="presentation"
                onClick={() => setStateAbout(false)}
                onKeyDown={() => setStateAbout(false)}
              >
                <GridRowItem>
                  <Grid item xs={3}>
                    <MainSubMenuItem primary={t('MainMenu-P11')} path={PATH_TECHNOLOGIES} />
                  </Grid>
                  <Grid item xs={4}>
                    <MainSubMenuItem primary={t('MainMenu-P12')} path={PATH_BOARD_DIRECTORS} />
                  </Grid>
                  <Grid item xs={5}>
                    <MainSubMenuItem primary={t('MainMenu-P13')} path={PATH_EXECUTIVE_MANAGEMENT} />
                  </Grid>
                  <Grid item xs={3}>
                    <MainSubMenuItem primary={t('MainMenu-P22')} path={PATH_ADVISORS} />
                  </Grid>
                  <Grid item xs={4}>
                    <MainSubMenuItem primary={t('MainMenu-P21')} path={PATH_NEWS_AND_MEDIA} />
                  </Grid>
                  <Grid item xs={4}>
                    <MainSubMenuItem primary={t('MainMenu-P29')} path={PATH_FAQ} />
                  </Grid>
                </GridRowItem>
              </Box>
            </Grid>
          </GridRowFirst>
        </Grid>
      </Box>
    )
  }, [])

  const drawer = (
    <div
      onClick={() => {
        setMobileOpen(false)
      }}
    >
      <Toolbar className={classes.logoMenu}>
        <Logo />
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          className={classes.menuButton}
          onClick={toggleDrawerMobile}
        >
          <MenuIconClose />
        </IconButton>
      </Toolbar>

      <List className={classes.mainMenu}>
        <MainMenuItem
          primary={t('MainMenu-P15')}
          path={PATH_ABOUT_PINOBO}
          className={classes.mobMenuItemBold}
        />
        <MainMenuItem primary={t('MainMenu-P3')} path={PATH_OVERVIEW} />

        <MainMenuItem primary={t('MainMenu-P6')} path={PATH_MEDIA_INFLUENCERS} />
        <MainMenuItem primary={t('MainMenu-P4')} path={PATH_AFFILIATE_NETWORKS} />
        <MainMenuItem primary={t('MainMenu-P5')} path={PATH_MARKET_PLACES} />
        <MainMenuItem primary={t('MainMenu-P7')} path={PATH_ECOMMERCE} />
        <MainMenuItem primary={t('MainMenu-P8')} path={PATH_FINTECHS} />

        <MainMenuItem
          primary={t('MainMenu-P20')}
          path={PATH_SERVICES}
          className={classes.mobMenuItemBold}
        />
        <MainMenuItem primary={t('MainMenu-P26')} path={PATH_VIRTUAL_IBAN_ACCOUNTS} />
        <MainMenuItem primary={t('MainMenu-P25')} path={PATH_CROSS_BORDER_PAYMENTS} />
        <MainMenuItem primary={t('MainMenu-P27')} path={PATH_API} />

        <MainMenuItem primary={t('MainMenu-P23')} path={PATH_SERVICES} />
        <MainMenuItem primary={t('MainMenu-P19')} path={PATH_APPLE_PAY} />
        <MainMenuItem primary={t('MainMenu-P24')} path={PATH_GOOGLE_PAY} />

        <MainMenuItem
          primary={t('MainMenu-P16')}
          path={PATH_TECHNOLOGIES}
          className={classes.mobMenuItemBold}
        />
        <MainMenuItem primary={t('MainMenu-P11')} path={PATH_KLARPAY} />

        <MainMenuItem primary={t('MainMenu-P12')} path={PATH_BOARD_DIRECTORS} />
        <MainMenuItem primary={t('MainMenu-P13')} path={PATH_EXECUTIVE_MANAGEMENT} />
        <MainMenuItem primary={t('MainMenu-P22')} path={PATH_ADVISORS} />
        <MainMenuItem primary={t('MainMenu-P21')} path={PATH_NEWS_AND_MEDIA} />
        <MainMenuItem primary={t('MainMenu-P29')} path={PATH_FAQ} />

        <MainMenuItem
          primary={t('MainMenu-P28')}
          path={PATH_SOLUTIONS}
          className={classes.mobMenuItemBold}
        />

        <MainMenuItem
          primary={t('MainMenu-P30')}
          path={PATH_PRICING}
          className={classes.mobMenuItemBold}
        />

        <MainMenuItem
          primary={t('TalkToUs')}
          path={PATH_TALKTOUS}
          className={classes.mobMenuItemBold}
        />
      </List>
      <Box m={1}>
        <Grid container spacing={1} direction={'column'}>
          <Grid item>
            <Box textAlign={'center'}>
              {/* <LanguageSwitch /> */}
              <Login />
            </Box>
          </Grid>
          <Grid item>
            <Account />
          </Grid>
        </Grid>
      </Box>
    </div>
  )

  return (
    <>
      <Grid container spacing={1} justify={'center'}>
        <Grid item xs={12} lg={10} xl={6}>
          <Hidden mdUp>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="end"
              className={classes.menuButton}
              onClick={toggleDrawerMobile}
            >
              <MenuIcon />
            </IconButton>
            <Drawer
              variant="temporary"
              anchor={theme.direction === 'rtl' ? 'right' : 'left'}
              open={mobileOpen}
              onClose={toggleDrawerMobile}
              classes={{
                paper: classes.drawerPaper,
              }}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
            >
              {drawer}
            </Drawer>
          </Hidden>
          <Hidden smDown>
            <MenuList className={classes.tp}>
              {/* <MainMenuItem primary={t('MainMenu-P14')} path={PATH_ROOT} /> */}
              <MainMenuItem
                icon={<ArrowDown />}
                primary={t('MainMenu-P15')}
                path={[
                  PATH_ABOUT_PINOBO,
                  PATH_MEDIA_INFLUENCERS,
                  PATH_AFFILIATE_NETWORKS,
                  PATH_ECOMMERCE,
                  PATH_MARKET_PLACES,
                  PATH_FINTECHS,
                ]}
                setState={setState}
              />
              <MainMenuItem
                icon={<ArrowDown />}
                primary={t('MainMenu-P20')}
                path={[
                  PATH_VIRTUAL_IBAN_ACCOUNTS,
                  PATH_CROSS_BORDER_PAYMENTS,
                  PATH_API,
                  PATH_SERVICES,
                  PATH_APPLE_PAY,
                  PATH_GOOGLE_PAY,
                ]}
                setState={setFeature}
              />
              <MainMenuItem
                icon={<ArrowDown />}
                primary={t('MainMenu-P16')}
                path={[
                  PATH_TECHNOLOGIES,
                  PATH_BOARD_DIRECTORS,
                  PATH_EXECUTIVE_MANAGEMENT,
                  PATH_ADVISORS,
                  PATH_NEWS_AND_MEDIA,
                  PATH_NEWS_ENTRIES,
                  PATH_FAQ,
                ]}
                setState={setStateAbout}
              />
              <MainMenuItem primary={t('MainMenu-P28')} path={PATH_SOLUTIONS} />
              <MainMenuItem primary={t('MainMenu-P30')} path={PATH_PRICING} />
              <MainMenuItem primary={t('MainMenu-P31')} path={PATH_PORTFOLIO} />
              {/* <MainMenuItem primary={t('TalkToUs')} path={PATH_TALKTOUS} /> */}
            </MenuList>

            <Drawer
              className={classes.drawerSize}
              anchor={'top'}
              open={state}
              onClick={() => setState(false)}
            >
              {list()}
            </Drawer>

            <Drawer
              className={classes.drawerSize}
              anchor={'top'}
              open={feature}
              onClick={() => setFeature(false)}
            >
              {listFeatures()}
            </Drawer>

            <Drawer anchor={'top'} open={stateAbout} onClose={() => setStateAbout(false)}>
              {listAbout('top')}
            </Drawer>
          </Hidden>
        </Grid>
      </Grid>
    </>
  )
}
